<template>
    <div class="today-meeting-hot">
        <div class="photo-container">
            <div class="watermark">
                <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="flex-row">
                    <div class="pn">{{ this.$store.getters.me.phone_number }}</div>
                </div>
                <div class="" style="text-align: center">{{ this.$store.getters.me.phone_number }}</div>
            </div>
            <img v-if="photoURL" class="photo" v-lazy="post.photo_url" @error="$event.target.src = $blankProfile" />
            <div v-else class="photo etc"></div>
            <div class="badges">
                <div v-if="type === 'all'" class="badge-box">
                    <div v-if="post.participant_count > 99" class="badge-contents">
                        <img class="participant" src="@/assets/images/profile-user.png" />
                        99+
                    </div>
                    <div v-else class="badge-contents">
                        <img class="participant" src="@/assets/images/profile-user.png" />
                        {{ post.participant_count }}
                    </div>
                </div>
            </div>
        </div>

        <div class="title lines-1" v-if="type !== 'all'">{{ post.title }}</div>
        <div class="info-user" :class="{ not_all: type !== 'all' }">
            <!-- <div class="region" v-html="regionString" /> -->
            <div
                class="category-container"
                :class="{ male: post.host_info.gender === 0, female: post.host_info.gender === 1 }"
                v-if="type === 'all'"
            >
                <div class="category lines-1" v-if="type === 'all'">{{ post.category }}</div>
            </div>
            <div class="flex-row" v-if="type === 'all'">
                <!-- <div
                    class="gender flex-wrap"
                    :class="{ male: post.host_info.gender === 0, female: post.host_info.gender === 1 }"
                ></div> -->
                <div class="title lines-1" v-if="type === 'all'">{{ post.title }}</div>
            </div>
        </div>

        <!-- <div v-if="type !== 'all'" class="status">
            <div v-if="type === 'myPost' && post.participants.length > 0" class="interested-count">
                {{ participants.length }}명이 관심있어요!
            </div>
            <div v-else-if="type === 'interested'" class="status-string">
                {{ statusString }}
            </div>

            <img v-if="showNewBadge" class="new-badge" src="@/assets/images/today-meeting/new_badge.png" />
        </div> -->
    </div>
</template>

<script>
export default {
    name: 'TodayMeetingPost',
    props: {
        post: Object,
        type: {
            type: String,
            required: true,
        },
        from: String,
    },
    data: () => ({
        dayOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
    }),
    computed: {
        photoURL() {
            if (this.post.photo_url.includes('app_photos') && this.post.category === '기타') {
                return false
            }

            return this.post.photo_url
        },
        dateAndCategory() {
            if (!this.post) return ''

            const category = this.post.category

            const today = this.$moment().format('YYYY-MM-DD')
            const expireAt = this.$moment(this.post.expire_at)

            const diff = expireAt.diff(today, 'days')

            let expireAtToString
            if (diff < 0) expireAtToString = '마감'
            else if (diff === 0) expireAtToString = '오늘'
            else if (diff === 1) expireAtToString = '내일'

            if (this.type === 'all') return `${expireAtToString}, ${category}`

            return diff < 0
                ? '마감'
                : `${expireAtToString} (${expireAt.format('MM.DD')} ${this.dayOfWeek[expireAt.day()]})`
        },
        statusString() {
            if (!this.participant) return ''

            if (this.isPaid) {
                if (this.isPaidBoth) {
                    return '연락처 교환 완료'
                    // } else if (this.participant.host_paid) {
                    //     return '상대의 만남 수락'
                    // } else if (this.participant.participant_paid) {
                    //     return '내가 만남 수락함'
                }
            }

            const { status } = this.participant

            if (status === 'request') return '상대 확인 전'
            if (status === 'checked') return '상대가 내 프로필 확인 중'
            if (status === 'accepted' || status === 'par_checked') return '내 수락 기다리는 중'
            if (status === 'done') return '연락처 교환 완료!'

            return ''
        },
        regionString() {
            if (this.post.state === '서울' || this.post.state === '경기') {
                const regionDetail = this.$store.getters.eventRegions.find(
                    region => region.id === this.post.event_region_id,
                )
                if (regionDetail) {
                    return `${this.post.state}(${regionDetail.name})`
                } else {
                    return `${this.post.state}`
                }
            } else {
                return `${this.post.state}`
            }
        },
        participant() {
            // 내가 관심있는 포스트에 있음
            return this.post.participant
        },
        participants() {
            // 내가 만든 포스트에 있음
            return this.post.participants
        },
        isPrivacy() {
            return this.post.profile_style === 'privacy' || !this.post.profile_style
        },
        isPaid() {
            return this.participant.participant_paid || this.participant.host_paid
        },
        isPaidBoth() {
            return this.participant.participant_paid && this.participant.host_paid
        },
        showNewBadge() {
            if (this.type === 'myPost') {
                return this.post.participants.some(p => !p.host_checked)
            }

            if (this.type === 'interested') {
                return !this.post.participant.participant_checked
            }

            return false
        },
    },
}
</script>

<style lang="scss" scoped>
.today-meeting-hot {
    display: flex;
    flex-direction: column;

    .photo-container {
        position: relative;
        overflow: hidden;
        aspect-ratio: 1/1;
        .photo {
            margin-bottom: 3px;
            border-radius: 8px;
            border: solid 1px $grey-02;

            &.etc {
                color: white;
                background-image: linear-gradient(135deg, #ba90ff, $purple-primary);
                display: flex;
                flex-direction: column;
                justify-content: center;

                .date {
                    padding: 1px 10px;
                    background: white;
                    font-size: 12px;
                    color: $purple-primary;
                    width: fit-content;
                    margin: 0 auto 12px auto;
                    border-radius: 6px;
                }
                .region {
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
        .watermark {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.1);
            position: absolute;
            height: 100%;
            width: 300%;
            transform: rotate(-45deg);
            top: -10;
            left: -90%;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .pn {
                margin-right: 35px;
            }
        }
        .mini-photo {
            position: absolute;
            right: 0;
            bottom: 0;
            margin-right: 7px;
            width: 40px;
            height: 40px;
            border-radius: 16px;
            border: solid 1px $grey-04;
        }

        .badges {
            position: absolute;
            right: 8px;
            bottom: 6px;
            // display: flex;
            // flex-wrap: wrap;
            // flex-direction: column;

            .badge-box {
                font-size: 12px;
                color: white;
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 6px;
                padding: 2px 2px;
                // height: 20px;
                display: flex;
                // float: right;
                // @include center;
                @include f-medium;

                .badge-contents {
                    img {
                        height: 12px;
                        width: 12px;
                        margin: 0 6px;
                    }

                    padding-right: 3px;
                }
            }
        }
    }

    .info {
        display: flex;
        align-items: center;

        .date-category {
            font-size: 12px;
            color: $purple-primary;

            @include f-bold;

            &.not_all {
                margin-top: 2px;
                color: $grey-07;
                @include f-medium;
            }
        }
    }

    .gender {
        margin-right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 40%;
        align-self: center;

        &.male {
            background-color: $blue-man;
            color: $blue-man;
        }

        &.female {
            background-color: $pink-woman;
            color: $pink-woman;
        }
    }

    .gender-text {
        // margin-right: 6px;
        // width: 10px;
        // height: 10px;
        // border-radius: 40%;
        font-size: 12px;
        @include f-bold;

        &.male {
            // background-color: $blue-man;
            color: $blue-man;
        }

        &.female {
            // background-color: $pink-woman;
            color: $pink-woman;
        }
    }

    .title {
        color: black;
        font-size: 15px;
        font-weight: bold;
    }

    .info-user {
        &.not_all {
            margin-top: 0px;
        }
        .region {
            font-size: 12px;
            color: $grey-07;
            @include f-medium;
            margin-bottom: 2px;
        }

        .content {
            font-size: 13px;
        }

        .category-container {
            padding: 0 10px;
            margin-top: 5px;
            width: fit-content;
            // border: 1px solid;
            border-radius: 10px;
            text-align: center;

            &.male {
                border: $blue-man 2px solid;
            }

            &.female {
                border: $pink-woman 2px solid;
            }

            .category {
                color: black;
                font-weight: bold;
                font-size: 12px;
            }
        }
    }

    .status {
        margin-top: 4px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $purple-primary;

        @include f-bold;

        .new-badge {
            height: 18px;
        }
    }
}
</style>
